/*
|--------------------------------------------------------------------------
| 404 Page.
|--------------------------------------------------------------------------
|
| The view that gets rendered when a user tries to visit a route that has
| no matching file in your /pages directory.
| If the user is authenticated, a link to the user home route (defined in your
| .env.local) will be displayed. Otherwise, a link to the homepage will be
| displayed.
|
*/
import { RecommendCompetitions } from '@/components/Competition'
import { AppLayout } from '@/components/Layouts/AppLayout'
import { useLanguage } from '@/hooks/Contexts'
import { Fragment } from 'react'

function NotFoundPage() {
  /**
   * Determine the link location that the link
   * on the page will lead to. If the user is
   * authenticated, it will be the user home route.
   * Otherwise it will be the homepage.
   */
  // const linkLocation = props.isAuthenticated ? process.env.NEXT_PUBLIC_USER_HOME_ROUTE : '/'

  const { not_Found } = useLanguage()

  return (
    <Fragment>
      <AppLayout title={not_Found['404_error']} />
      <div className='fluid-gray'>
        <div className='container-gray flex flex-col items-center gap-x-6 md:flex-row-reverse'>
          <img src='/assets/unsplash_heNwUmEtZzo.png' alt='Page not found image' className='w-full flex-1 rounded-lg md:w-1/2' />
          <div className='mt-4 flex-1'>
            <p className={`mb-4 text-lg font-semibold md:text-xl lg:text-2xl`}>{not_Found.looking_for_competition}</p>
            <p className={`text-base md:text-lg`}>{not_Found.paragraph}</p>
          </div>
        </div>
      </div>
      <RecommendCompetitions extraCls='container-white' />
    </Fragment>
  )
}

export default NotFoundPage
